import(/* webpackMode: "eager", webpackExports: ["TimeZoneChangeDetector"] */ "/vercel/path0/apps/web/src/app/[locale]/timezone-change-detector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/web/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/toaster.tsx");
